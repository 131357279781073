.storyHeaderContainer {
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
    flex-direction: column;
    padding: var(--spacing-size-six) 0;
}

.storyHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.storyHeaderDate {
    font-family: 'TWK Everett Mono', sans-serif;
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    text-transform: uppercase;
}

.storyHeaderHashtagButton {
    display: flex;
}

.divider {
    padding-top: var(--spacing-size-six);
    border-bottom: 1px solid var(--color-brand-aluminium);
}

@media screen and (max-width: 991px) {
    .storyHeaderContainer {
        width: 100%;
        padding: var(--spacing-size-five);
    }

    .storyHeader {
        flex-direction: column;
    }

    .storyHeaderDate {
        padding-bottom: var(--spacing-size-two);
    }
}
