.reviews {
    padding: var(--spacing-size-nine);
    background-color: var(--color-brand-aluminium);
}

@media screen and (max-width: 767px) {
    .reviews {
        padding: var(--spacing-size-five);
    }
}
