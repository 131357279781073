.navItems {
    display: grid;
    grid-row-gap: var(--spacing-size-two);
    padding: var(--spacing-size-three) 0;
}

.divider {
    display: block;
    border: none;
    width: 100%;
    margin: var(--spacing-size-four) 0;
    border-top: 1px solid var(--color-brand-black);
}

.navLeftIcon {
    display: none;
}

@media screen and (max-width: 991px) {
    .navHeader {
        display: flex;
        width: 100%;
    }

    .navHeading {
        text-align: center;
        width: inherit;
    }

    .navLeftIcon {
        display: block;
        padding: var(--spacing-size-three) var(--spacing-size-four) var(--spacing-size-three) 0;
    }

    .divider {
        margin: var(--spacing-size-three) 0 var(--spacing-size-two) 0;
    }
}
