.root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 180px 0;
}

.message h1 {
    font-size: 1.875rem;
    font-family: MonumentExtended-Regular, sans-serif;
    margin: 0;
}

.message p {
    line-height: 1.5em;
    margin: 5px 0;
    font-size: 0.95rem;
    color: rgb(34, 34, 34);
}

.link {
    font-size: 1.125rem;
    margin: 15px 0 40px;
    font-weight: 300;
    color: rgb(34, 34, 34);
    white-space: nowrap;
    line-height: 1.5em;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
}

.features {
    width: 100%;
    display: flex;
    font-size: 2.5rem;
    font-family: MonumentExtended-Regular, sans-serif;
    font-weight: 600;
}

.feature {
    flex: 1 0 50%;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
}

.feature a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    text-align: center;
    padding: 85px;
    width: 100%;
}

.feature:first-child {
    background-color: rgb(151, 169, 133);
}

.feature:last-child {
    background-color: rgb(204, 203, 198);
}

@media (max-width: 991px) {
    .features {
        flex-direction: column;
    }
}
