.header {
    font-size: 0.8em;
}

.resetForm {
    padding: var(--spacing-size-five) 0;
}

.inputField {
    padding: var(--spacing-size-two) 0;
    display: flex;
    flex-direction: column;
}

.buttonField {
    padding: var(--spacing-size-two) 0;
}
