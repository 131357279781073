.root {
    display: flex;
    flex: 1 0 100%;
    position: relative;
    padding: 0 var(--spacing-size-eight) 0 var(--spacing-size-nine);
    justify-content: space-between;
    align-items: center;
    background: var(--color-brand-black);
    color: var(--color-brand-white);
    --height: 88px;
    height: var(--height);
    gap: var(--spacing-size-three);
}

.content {
    line-height: var(--line-height-component2);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button {
    color: var(--color-brand-white);
    padding: var(--spacing-size-one);
}

@media screen and (max-width: 991px) {
    .root {
        padding: var(--spacing-size-two) var(--spacing-size-four) 0
            var(--spacing-size-five);
        --height: 130px;
        height: var(--height);
        flex-direction: column;
        gap: 0;
    }
    .buttons {
        width: 100%;
        margin-top: var(--spacing-size-two);
        margin-bottom: var(--spacing-size-four);
    }
}

.column {
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 991px) {
    .leftColumn {
        display: none;
    }
}

.end {
    justify-content: flex-end;
}

.dismissControl {
    flex: 1;
}

.title {
    text-decoration: underline;
}
