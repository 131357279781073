.root {
    display: flex;
}

.root.withBGImage {
    min-height: 1000px;
}

.column {
    width: 50%;
    align-items: center;
    justify-content: center;
}

.contentUL {
    padding-top: var(--spacing-size-six);
}

@media (max-width: 991px) {
    .root {
        flex-direction: column;
    }

    .column {
        padding: var(--spacing-size-seven) var(--spacing-size-seven);
        width: 100%;
    }
}

.columnLeft {
    position: relative;
    background: rgb(247, 247, 247);
}

.columnRight {
    padding: var(--spacing-size-six) var(--spacing-size-seven);
}

@media (max-width: 991px) {
    .columnRight {
        padding: 0 var(--spacing-size-seven);
    }
}

.poster {
    width: 100%;
    height: 100%;
}

.root.withBGImage .posterContent {
    position: absolute;
}

.posterContent {
    height: 100%;
    bottom: 0;
    z-index: 2;
}

.root.withBGImage textarea {
    border: 1px solid black;
}

.root input, .root textarea {
    background: none;
}

@media (min-width: 991px) {
    .posterBGImage {
        display: none;
    }

    @media (max-width: 991px) {
        .posterContent {
            display: flex;
        }
    }
}

@media (max-width: 991px) {
    .root.withBGImage .columnLeft {
        padding: 0;
    }

    .root.withBGImage .columnRight {
        padding: var(--spacing-size-six) var(--spacing-size-seven);
    }

    .root.withBGImage .posterContent {
        padding: var(--spacing-size-six) var(--spacing-size-seven);
    }

    .posterBGImage {
        z-index: 1;
        width: 100%;
    }
}
