.CollapsibleRoot {
    width: 300px;
    cursor: pointer;
}
.IconButton {
    font-family: inherit;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--color-brand-titanium);
    box-shadow: 0 2px 10px var(--color-brand-aluminium);
}
.IconButton[data-state='closed'] {
    background-color: var(--color-brand-white);
}
.IconButton[data-state='open'] {
    background-color: var(--color-surface-hover-invert);
}
.IconButton:hover {
    background-color: var(--color-surface-hover-invert);
}
.IconButton:focus {
    box-shadow: 0 0 0 2px var(--color-brand-aluminium);
}
.Text {
    font-family: 'TWK Evertt', sans-serif;
    font-weight: var(--font-weight-4);
    font-size: var(--font-size-copy2);
    line-height: var(--line-height-copy2);
}
.Repository {
    background-color: var(--color-brand-white);
    border-radius: 4px;
    margin: 10px 0;
    padding: 10px;
    box-shadow: 0 2px 10px var(--color-brand-aluminium);
    font-family: 'TWK Evertt', sans-serif;
    font-weight: var(--font-weight-4);
    font-size: var(--font-size-copy2);
    line-height: var(--line-height-copy2);
}
