.root {
    border-top: 5px solid var(--color-brand-pay);
    background-color: var(--color-brand-cement);
}

.root.warning {
    border-top: 5px solid var(--color-brand-warning);
    background-color: var(--color-brand-aluminium);
}

.sidebar {
    padding: var(--spacing-size-four) var(--spacing-size-nine) var(--spacing-size-four);
}

.page {
    padding: var(--spacing-size-three) var(--spacing-size-four) var(--spacing-size-four) var(--spacing-size-four);
}

.title {
    margin-bottom: var(--spacing-size-two);
}

.description {
    margin-bottom: var(--spacing-size-two);
}

@media screen and (max-width: 767px) {
    .sidebar {
        padding: var(--spacing-size-four) var(--spacing-size-five);
    }
}
