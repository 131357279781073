.fallbackText {
    font-size: var(--font-size-subtitle2);
    line-height: var(--line-height-subtitle2);
}

@media screen and (max-width: 767px) {
    .fallbackText {
        font-size: var(--font-size-subtitle3);
        line-height: var(--line-height-subtitle3);
    }
}
