.root {
    margin-bottom: var(--spacing-size-five);
}

.image {
    width: 100%;
}

.link {
    text-decoration: none;
    position: relative;
}

.link:hover .navCardOverlayOnImage {
    opacity: 0.3;
}

.navCardOverlayOnImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: var(--color-brand-white);
}
