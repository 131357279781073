.root {
    display: flex;
    border-top: 5px solid var(--color-brand-buy);
    background-color: rgb(240, 240, 240);
}

.sidebar {
    padding: var(--spacing-size-five) var(--spacing-size-nine) var(--spacing-size-five) ;
}

@media screen and (max-width: 767px) {
    .sidebar {
        padding: var(--spacing-size-four) var(--spacing-size-five) var(--spacing-size-four) var(--spacing-size-five);
    }
}

.login {
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    margin-right: var(--spacing-size-one);
}

.page {
    padding: var(--spacing-size-one) var(--spacing-size-seven) var(--spacing-size-five)  var(--spacing-size-four);
}

.title {
    margin: var(--spacing-size-three) 0;
}
