.root {
    display: flex;
    padding: var(--spacing-size-three) var(--spacing-size-eight) var(--spacing-size-eight);
    align-items: flex-end;
    flex-direction: column;
}

.closeText {
    margin-bottom: 6px;
}

@media screen and (max-width: 991px) {
    .root {
        padding: var(--spacing-size-three) var(--spacing-size-five) var(--spacing-size-six) var(--spacing-size-six);
    }
    .closeText {
        display: none;
    }
}

.inputContainer {
    width: 100%;
    position: relative;
    /* Keeps the close button aligned with the end on mobile while retaining a decent touch target area*/
    padding-right: var(--spacing-size-two);
}

.giantInput {
    width: 100%;
    border: none;
    border-bottom: 2px solid var(--color-brand-black);
    padding: var(--spacing-size-two) 0;
    background-color: var(--bg-color, var(--color-brand-white));
    font-size: var(--font-size-title4);
    line-height: var(--line-height-title4);
    font-weight: var(--font-weight-5);
}

@media screen and (max-width: 991px) {
    .giantInput {
        align-items: flex-end;
        font-size: var(--font-size-title5);
        line-height: var(--line-height-title5);
    }
}

.giantInput:focus {
    outline: none;
}

.searchButton {
    position: absolute;
    top: 20px;
    right: 15px;
}
