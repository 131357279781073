.root {
    position: relative;
}

.root img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.root:hover img {
    transform: scale(1.05);
}

.blankHeight {
    padding: var(--spacing-size-three) var(--spacing-size-two);
    display: block;
}

.root:hover .blankHeight {
    display: none;
}

.summary {
    padding: var(--spacing-size-three) var(--spacing-size-two);
    width: 100%;
    display: none;
}

.root:hover .summary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 1;
}

.title {
    padding-bottom: var(--spacing-size-one);
    text-align: center;
    height: 35px;
    width: 100%;
}

.priceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.compareAtPrice {
    text-decoration: line-through;
    color: var(--color-brand-steel);
    height: 16px;
    width: 100%;
}

.price {
    height: 16px;
    width: 100%;
    padding-top: var(--spacing-size-one);
}

.desktopOnly {
    display: block;
}

.mobileOnly {
    display: none;
}

@media screen and (max-width: 1300px) {
    .blankHeight {
        display: none;
    }

    .summary {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 1;
        padding: 0 var(--spacing-size-two);
    }

    .title {
        height: 50px;
    }

    .desktopOnly {
        display: none;
    }

    .mobileOnly {
        display: block;
    }
}

@media screen and (max-width: 991px) {
    .title {
        height: 35px;
    }
}

@media screen and (max-width: 320px) {
    .title {
        height: 50px;
    }
}
