.lookbookAlt {
    padding: var(--spacing-size-nine);
}

.dot {
    height: 12px;
    width: 12px;
    border-radius: 999px;
    background-color: var(--color-brand-pay);
    margin-left: var(--spacing-size-two);
    z-index: 2;
}

.lookbook_alt_image {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.initial_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease;
    cursor: pointer;
}

.secondary_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease;
    cursor: pointer;
}

.secondary_image {
    opacity: 0;
}

.lookbook_alt_image:hover .secondary_image {
    opacity: 1;
}

.lookbook_alt_image:hover .initial_image {
    opacity: 0;
}

@media screen and (max-width: 991px) {
    .lookbookAlt {
        padding: var(--spacing-size-five);
    }
}
