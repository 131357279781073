/* One Up Variant */
.oneUpRoot {
    display: flex;
    flex-direction: row;
}

.twoUpRoot {
    /*width: 40%;*/
}

/* Poster Variant */
.posterRoot {
    position: relative;
    box-sizing: border-box;
}

.posterContentPosition {
    position: absolute;
    bottom: var(--spacing-size-six);
    left: var(--spacing-size-five);
    margin: 0;
}

.posterOneUpWidth {
    width: 55%;
}

.title {
    font-family: 'TWK Everett', sans-serif;
    font-size: var(--font-size-title4);
    line-height: var(--line-height-title4);
    font-weight: var(--font-weight-5);
}

.buttonStyle {
    display: flex;
    padding: var(--spacing-size-three) var(--spacing-size-three);
}

/* Poster Feature Variant */
.posterFeatureRoot {
    display: flex;
}

.posterFeatureBG {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
    box-sizing: border-box;
}

.posterFeatureImage {
    position: relative;
    width: 50%;
}

.posterFeatureContent {
    display: none;
}

/* Poster Features */
.posterFeaturesRoot {
    display: flex;
}

.cover {
    display: grid;
    grid-template-areas: 'cover';
}

.cover > * {
    grid-area: cover;
}

.posterFeaturesItem {
    --min-height: 32.75rem;
    position: relative;
    flex: 1;
    min-height: var(--min-height);
    width: 50%;
}

.posterFeaturesItem > div > img,
.posterFeaturesItem > div > video {
    height: 100%;
    object-fit: cover;
}

.posterFeaturesItem:has(> div > video) {
    min-height: unset;
}

.overlayFeaturesContent {
    padding: var(--spacing-size-nine);
    position: relative;
    min-width: fit-content;
}

.buttonPosterFeatures {
    place-self: center;
    align-self: end;
    padding-bottom: var(--spacing-size-nine);
    z-index: 1;
}

/* Poster Features Alt */
.context {
    mix-blend-mode: difference;
    color: var(--color-brand-white);
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    font-weight: var(--font-weight-3);
    letter-spacing: -0.5px;
}

.overlayFeaturesAltContent {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.buttonPlacement {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 767px) {
    .twoUpRoot {
        width: 100%;
    }

    /* Poster Variant*/
    .posterContentPosition {
        bottom: var(--spacing-size-three);
        left: var(--spacing-size-three);
    }

    .title {
        font-size: var(--font-size-subtitle2);
        line-height: var(--line-height-subtitle2);
    }

    .buttonStyle {
        padding: var(--spacing-size-one) 0;
    }

    /* Poster Feature Variant */
    .posterFeatureBG {
        display: none;
    }

    .posterFeatureImage {
        width: 100%;
    }

    .posterFeatureContent {
        padding: var(--spacing-size-six) var(--spacing-size-five);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
    }
}

@media screen and (max-width: 991px) {
    .twoUpRoot {
        width: 100%;
    }

    .posterFeatureBG {
        padding: var(--spacing-size-six) var(--spacing-size-five);
    }

    .featureStackBG {
        padding: var(--spacing-size-six) var(--spacing-size-five);
    }

    /* Poster Features */
    .posterFeaturesRoot {
        flex-direction: column;
    }

    .overlayFeaturesContent {
        padding-inline: var(--spacing-size-five);
        padding-block-start: var(--spacing-size-six);
        padding-block-end: 1.75rem; /* No token for this in MMDS but it is used in the mmds figma file here */
    }

    .buttonPosterFeatures {
        padding-bottom: var(--spacing-size-five);
    }

    .posterFeaturesItem {
        width: 100%;
    }
}
