.form {
    padding: var(--spacing-size-seven) 0;
}

.eventMessageZone {
    display: flex;
    padding: var(--spacing-size-seven) 0;
}

@media (max-width: 919px) {
    .eventMessageZone {
        padding: var(--spacing-size-four) 0;
    }
}

.challengeMessageZone {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-size-seven) 0;
}

@media (max-width: 919px) {
    .challengeMessageZone {
        padding: var(--spacing-size-four) 0;
    }
}

.section {
    margin: 0 auto;
}

.messageSection {
    padding: var(--spacing-size-four) 0;
}

@media (min-width: 919px) {
    .section {
        margin: 0 auto;
    }

    .form {
        width: 100%;
        /*padding: var(--spacing-size-nine) var(--spacing-size-nine);*/
    }
}

.complete__section {
    margin: auto;
    padding: 30px 0;
}

/* NEW STYLES */
.stack > * + * {
    margin-block-start: var(--flow-space, var(--spacing-size-three));
}

.stack[data-flow-space='small'] > * + * {
    --flow-space: var(--spacing-size-four);
}

.stack[data-flow-space='large'] > * + * {
    --flow-space: var(--spacing-size-five);
}

.consent {
    max-width: 100%;
}

.terms {
    color: var(--text-secondary);
    max-width: 100%;
}

.terms a {
    text-decoration: underline;
    text-underline-position: under;
}

.terms a:hover {
    color: var(--text-secondary);
}

.validationMessage {
    color: var(--color-brand-Error);
    margin-block-start: var(--spacing-size-four);
    font-size: var(--font-size-component1);
}

/* Start react phone input styles */
.phoneInput > div + input {
    border: none;
    font: unset;
}

.phoneInput {
    border: 1px solid var(--surface-brand);
    margin-top: var(--spacing-size-two);
    padding: var(--spacing-size-two);
    background: none;
    width: 100%;
}

.phoneInput::placeholder {
    color: var(--text-secondary);
}

.textArea {
    width: 100%;
    height: 170px;
    margin-top: var(--spacing-size-two);
    padding: var(--spacing-size-two);
}

/* End react phone in put styles */
