.root {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    flex-direction: column;
}

.summary {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: var(--spacing-size-five) var(--spacing-size-nine);
}

.buttons {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 767px) {
    .summary {
        padding: var(--spacing-size-five);
    }
}