.swiperRoot {
    padding-top: var(--spacing-size-nine);
    padding-bottom: var(--spacing-size-nine);
}

.contextContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--spacing-size-nine);
    padding-right: var(--spacing-size-nine);
}

.context {
    font-family: 'TWK Everett Mono', sans-serif;
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    font-weight: var(--font-weight-3);
    color: var(--text-primary);
    padding: var(--spacing-size-one) 0;
    letter-spacing: -0.25px;
    display: flex;
    align-items: center;
    word-wrap: normal;
    white-space: nowrap;
}

.description {
    font-family: 'TWK Everett Mono', sans-serif;
    font-size: var(--font-size-component1);
    line-height: var(--line-height-copy1);
    font-weight: var(--font-weight-3);
    padding: var(--spacing-size-three);
    color: var(--text-primary);
}

.iconContainer {
    display: flex;
    z-index: 0;
}

/* LookBook Style */
.lookBookDescription {
    display: grid;
    grid-row-gap: var(--spacing-size-three);
    font-family: 'TWK Everett Mono', sans-serif;
    font-weight: var(--font-weight-3);
    font-size: var(--font-size-copy3);
    line-height: var(--line-height-copy2);
    color: var(--text-primary);
    padding: var(--spacing-size-three);
}

.variantTitle {
    color: var(--color-brand-titanium);
}

/* Collection Swiper Style */
.swiperColContainer {
    position: relative;
}

.swiperColContent {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
}

.overlayContext {
    font-family: 'TWK Everett Mono', sans-serif;
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    font-weight: var(--font-weight-3);
    color: var(--color-brand-white);
    letter-spacing: -0.25px;
    padding: var(--spacing-size-five);
}

.overlayTitle {
    font-family: 'TWK Everett', sans-serif;
    font-size: var(--font-size-copy1);
    line-height: var(--line-height-copy1);
    font-weight: var(--font-weight-4);
    color: var(--color-brand-white);
    padding: var(--spacing-size-five) var(--spacing-size-nine);
}

.overlayButton {
    padding: var(--spacing-size-five);
}

.overlayOnImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: var(--color-brand-white);
}

/* Linkable Wrapper */
.linkableWrapper {
    text-decoration: none;
    position: relative;
}

.linkableWrapper:hover .overlayOnImage {
    opacity: 0.3;
}

.linkableWrapper:hover .overlayButton .linkableWrapperButton {
    background-color: var(--surface-invert);
    color: var(--text-invert);
}

/* Capsule Swiper */
.capsuleButton {
    padding: var(--spacing-size-one) 0;
}

/* Product Flatlay */
.productFlatLayContent {
    padding-top: var(--spacing-size-nine);
    padding-left: var(--spacing-size-nine);
}

.productFlatLayTitle {
    font-family: 'TWK Everett', sans-serif;
    font-size: var(--font-size-title3);
    line-height: var(--line-height-title3);
    font-weight: var(--font-weight-5);
    padding-top: var(--spacing-size-five);
    padding-left: var(--spacing-size-nine);
    padding-bottom: var(--spacing-size-seven);
}

.productFlatLayDescription {
    width: 685px;
    font-family: 'TWK Everett', sans-serif;
    font-size: var(--font-size-subtitle1);
    line-height: var(--line-height-subtitle1);
    font-weight: var(--font-weight-5);
}

.productFlatLayButtons {
    display: flex;
    padding-top: var(--spacing-size-six);
}

.image {
    position: relative;
    background: var(--surface-product);
}

/* Product Flatlay Tabbed */
.productFlatLayCollectionHandle {
    display: -webkit-box;
    width: 100%;
}

.productFlatLayCollectionButton {
    padding-left: var(--spacing-size-two) !important;
    padding-right: var(--spacing-size-two) !important;
}

.divider {
    padding-left: var(--spacing-size-two);
    padding-right: var(--spacing-size-three);
}

/* Product SKU */
.productSKUDesktop {
    display: flex;
    justify-content: center;
    column-gap: var(--spacing-size-three);
}

.productSKUMobile {
    display: none;
}

/* PDP Gallery */
.PDPGalleryDesktop {
    height: 100vh;
}

/* Tabbed Card */
.tabbedCardTabSelector {
    display: -webkit-box;
    width: 100%;
}

.tabbedCardTabButtons {
    padding-left: var(--spacing-size-two) !important;
    padding-right: var(--spacing-size-two) !important;
}

/* Nosto Product Recommendation Card */
.upSellCrossSellSwiperRoot {
    padding-top: var(--spacing-size-six);
    padding-bottom: var(--spacing-size-six);
}

.upSellCrossCartPageSwiperRoot {
    padding-top: 0;
    padding-bottom: var(--spacing-size-six);
}

.upSellCrossCartPageSwiperRoot .contextContainer {
    padding-right: 0;
}

@media screen and (max-width: 991px) {
    .swiperRoot {
        padding-top: var(--spacing-size-six);
        padding-bottom: var(--spacing-size-six);
    }

    .contextContainer {
        padding-right: 0;
        padding-left: var(--spacing-size-five);
    }

    .description {
        padding: var(--spacing-size-three) var(--spacing-size-two);
    }

    .iconContainer {
        display: none;
    }

    .context {
        width: 100%;
    }

    /* LookBook Style */
    .lookBookDescription {
        padding: var(--spacing-size-three) var(--spacing-size-two);
    }

    /* Collection Swiper Style */
    .overlayContext {
        padding: var(--spacing-size-four) var(--spacing-size-five);
    }

    .overlayButton {
        padding: var(--spacing-size-five) var(--spacing-size-four);
    }

    /* Product Flatlay */
    .productFlatLayTitle {
        font-size: var(--font-size-title5);
        line-height: var(--line-height-title5);
        padding-left: var(--spacing-size-five);
        padding-bottom: 0;
    }

    .productFlatLayDescription {
        width: 100%;
        font-size: var(--font-size-subtitle2);
        line-height: var(--line-height-subtitle2);
    }

    .productFlatLayContent {
        padding: var(--spacing-size-five);
    }

    .productFlatLayButtons {
        flex-wrap: wrap;
    }

    .productFlatLayCollectionHandle {
        overflow-x: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Edge */
    }

    .productFlatLayCollectionHandle::-webkit-scrollbar {
        display: none;
    }

    /* Product SKU Feature*/
    .productSKUMobile {
        display: block;
    }

    .productSKUDesktop {
        display: none;
    }

    .productSKUSingleImage {
        padding: 0 var(--spacing-size-five);
    }

    .upSellCrossCartPageSwiperRoot {
        padding-top: var(--spacing-size-seven);
    }
}

@media screen and (max-aspect-ratio: 3/4) {
    .PDPGalleryDesktop {
        height: auto;
        max-height: 80vh;
    }
}
