.tabs {
    display: flex;
    flex-direction: column;
}

.tab {
    display: flex;
    padding: var(--spacing-size-five) 0;
}

.iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    border: 0;
}

.closeButton {
    z-index: 5;
    position: absolute;
    right: var(--spacing-size-three);
}

.body {
    padding: 0 !important;
}

@media screen and (min-width: 991px) {
    .body {
        max-width: 504px;
    }

    .closeButton {
        display: none;
    }
}
