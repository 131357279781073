.productCardISO {
    color: var(--text-primary);
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    font-family: 'TWK Everett Mono', sans-serif;
    font-weight: 300;
}

.productCardISOContainer {
    position: relative;
    display: inline-block;
}

.badge {
    position: absolute;
    left: var(--spacing-size-three);
    top: var(--spacing-size-three);
    text-align: left;
}

.summary {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-size-four) var(--spacing-size-three);
    color: var(--text-secondary);
}

.compareAtPrice {
    color: var(--color-brand-titanium);
    text-decoration: line-through;
    margin-right: var(--spacing-size-two);
}
