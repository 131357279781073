.region {
    padding-block-end: var(--fluid-48-72);
    padding-inline: var(--fluid-24-72);
}

.root {
    background-color: var(--color-brand-cement);
}

.stack > * + * {
    margin-block-start: var(--flow-space, var(--spacing-size-six));
}

.row {
    border-bottom: 1px solid var(--color-brand-aluminium);
    padding-block: var(--flow-space, var(--fluid-16-32));
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--color-brand-cement);
}

.text {
    color: var(--text-secondary);
}

@media screen and (max-width: 767px) {
    .text {
        font-size: var(--font-size-copy3);
    }

    .grid {
        grid-template-columns: 1fr;
    }
}
