.root {
    display: flex;
    align-items: center;
    color: var(--text-primary);
    height: 100%;
    background-color: var(--background-color);
}

.root.desktop {
    padding: var(--spacing-size-five) var(--spacing-size-nine);
}

.root.mobile {
    padding: var(--spacing-size-five) var(--spacing-size-five);
}

.products {
    background-color: transparent;
}

.primary {
    display: flex;
    justify-content: flex-start;
    flex: 1;
}

.item {
    height: 24px;
    padding-right: var(--spacing-size-four);
}

.item.mobile {
    padding-right: var(--spacing-size-six);
}

.support {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    column-gap: var(--spacing-size-five);
    align-items: center;
}

.support.mobile {
    column-gap: unset !important;
}

.supportIcons {
    display: flex;
    padding-left: var(--spacing-size-one);
}

.icon {
    position: relative;
    height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 var(--spacing-size-four) 0 var(--spacing-size-four);
}

.flag {
    width: 18px;
    padding-right: var(--spacing-size-one);
}

.supportItem {
    position: relative;
    height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.supportItem.mobile {
    padding-left: var(--spacing-size-six);
}

.flag {
    margin-right: calc(var(--spacing-size-one ) / 2);
}

.mobileOnly {
    display: none;
}

.desktopOnly {
    display: initial;
}

@media screen and (max-width: 991px) {
    .root {
        width: initial;
    }

    .mobileOnly {
        display: initial;
    }

    .desktopOnly {
        display: none;
    }
}
