.desktopOnly {
    display: initial;
}
.mobileOnly {
    display: none;
}
@media screen and (max-width: 767px) {
    .desktopOnly {
        display: none;
    }
    .mobileOnly {
        display: initial;
    }
}
