.root {
    display: flex;
    padding: var(--spacing-size-five) var(--spacing-size-nine);
}

.root.large {
    padding: var(--spacing-size-five) var(--spacing-size-nine);
}

.empty {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 991px) {
    .root {
        padding: var(--spacing-size-five);
    }

    .root.large {
        padding: var(--spacing-size-five) 0 0 var(--spacing-size-five);
    }

    .heading > h1[class*='mmds-title-four'] {
        font-size: var(--font-size-subtitle2);
        line-height: var(--line-height-subtitle2);
    }
}
