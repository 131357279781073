.container {
    padding: var(--spacing-size-eight) var(--spacing-size-nine);
    width: max(min(100%, 56.75rem), 66%);
}

.context {
    padding-bottom: var(--spacing-size-five);
}

.shortText a {
    text-decoration: underline;
}

.fullText a {
    text-decoration: underline;
}

.expandButton {
    padding: var(--spacing-size-four) 0;
    background: none;
    border: none;
}

@media screen and (max-width: 767px) {
    .container {
        padding: var(--spacing-size-six) var(--spacing-size-five);
    }

    .context[class*='mmds-copy-one'], .shortText[class*='mmds-copy-one'], .fullText[class*='mmds-copy-one'], .expandButton[class*='mmds-copy-one'] {
        font-size: var(--font-size-copy2);
        line-height: var(--line-height-copy2);
    }
}
