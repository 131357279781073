.root {
    background-color: var(--color-brand-black);
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.responsive.desktopOnly {
    width: 100%;
}

.responsive.mobileOnly {
    width: 100%;
}

.root.desktop {
    padding: var(--spacing-size-nine);
}

.primaryItem {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.primaryLabel {
    color: var(--color-brand-white);
    padding-bottom: var(--spacing-size-five);
}

.primaryChildren {
    color: var(--color-brand-aluminium);
    gap: var(--spacing-size-one);
    display: flex;
    flex-direction: column;
}

.root.desktop {
    display: flex;
}

/* Mobile Nav */
.navContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing-size-six) var(--spacing-size-five) var(--spacing-size-seven) var(--spacing-size-five);
}

.navItems {
    flex: 1 0 100%;
}

.navItem {
    flex: 1 0 100%;
}

.copyright {
    background-color: var(--color-brand-white);
    padding: var(--spacing-size-six) var(--spacing-size-nine);
}

.mobileOnly {
    display: none;
}

.desktopOnly {
    display: initial;
}

@media screen and (max-width: 991px) {
    .root.mobile {
        display: flex;
        flex-direction: column;
    }

    .root.desktop {
        display: none;
    }

    .divider {
        height: 1px;
        background-color: var(--color-brand-titanium);
        margin: 0 var(--spacing-size-five);
    }

    .copyright {
        padding: var(--spacing-size-six) var(--spacing-size-five);
    }

    .mobileOnly {
        display: initial;
    }

    .desktopOnly {
        display: none;
    }
}
