.root {
    display: flex;
    padding: var(--spacing-size-three) var(--spacing-size-nine) 0 var(--spacing-size-nine);
    flex-direction: column;
}

@media screen and (max-width: 991px) {
    .root {
        padding: var(--spacing-size-three) var(--spacing-size-nine) 0 var(--spacing-size-five);
    }
}

.notice {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-size-three);
}

.noticeTitle {
    padding: 0 var(--spacing-size-nine) var(--spacing-size-two) 0;
}

.noticeText {
    padding: var(--spacing-size-two) 0 var(--spacing-size-five) 0;
    color: var(--color-brand-black)
}

.divider {
    display: block;
    border-top: 1px solid var(--color-brand-black);
    margin: var(--spacing-size-six) 0;
}

.navItem {
    padding: 0 var(--spacing-size-three) 0 0;
}

.button {
    padding: var(--spacing-size-four) 0 0 0;
}

.selected {
    font-weight: 500;
}

.flag {
    margin-right: var(--spacing-size-two)
}
