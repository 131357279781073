.root {
    display: flex;
    flex: 1 0 100%;
    position: relative;
    padding: 0 var(--spacing-size-eight) 0 var(--spacing-size-nine);
    justify-content: space-between;
    align-items: center;
    --height: 43px;
    min-height: var(--height);
}

.button {
    padding: var(--spacing-size-one);
}

.hidden {
    height: 0;
}

.column {
    display: flex;
    justify-content: center;
}

.link {
    text-decoration: underline;
}

.content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: var(--spacing-size-three);
    padding-top: var(--spacing-size-two);
    padding-bottom: var(--spacing-size-two);
}

.end {
    justify-content: flex-end;
}

.dismissControl {
    flex: 1;
}

.in {
    transition: height 0.2s ease-in;
    height: var(--height);
}

.out {
    transition: height 0.2s ease-out;
    height: 0;
}

@media screen and (max-width: 991px) {
    .root {
        padding: 0 var(--spacing-size-four) 0 var(--spacing-size-five);
    }
}

@media screen and (max-width: 767px) {
    .noLink {
        width: 100%;
        text-align: center;
    }
}
