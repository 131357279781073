.root {
    align-items: center;
    background: linear-gradient(110deg, rgba(0,0,0,0) 15%, var(--color-brand-cement) 15%);
    bottom: var(--spacing-size-four);
    cursor: pointer;
    display: flex;
    gap: var(--spacing-size-two);
    padding: var(--spacing-size-two) 0;
    padding-left: var(--spacing-size-six);
    padding-right: var(--spacing-size-four);
    position: fixed;
    right: 0;
    z-index: 2;
}

.icon {
    background-color: var(--color-brand-pay);
    border-radius: 5px;
    height: 12px;
    width: 12px;
}
