.textBlock {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.halfWidth {
    width: 42%;
}

.twoThirdsWidth {
    width: 63%;
}

.third {
    width: 20%;
}

.leftAligned {
    justify-content: left;
    text-align: left;
}

.rightAligned {
    justify-content: right;
    text-align: right;
}

.centerAligned {
    justify-content: center;
    text-align: center;
}

.featureHeading {
    font-family: "TWK Everett", sans-serif;
    font-size: var(--font-size-title3);
    line-height: var(--line-height-title3);
    font-weight: var(--font-weight-5);
    padding: var(--spacing-size-seven) 0;
}

.featureText {
    line-height: 1.4rem;
    font-weight: var(--font-weight-4);
}

.featureTextSpacing {
    padding-top: var(--spacing-size-seven);
    padding-bottom: var(--spacing-size-seven);
}

.featureText a {
    text-decoration: underline;
    text-underline-mode: under;
}

.text {
    font-family: 'Rhymes Display', sans-serif;
    font-size: var(--font-size-subtitle2);
    line-height: var(--line-height-subtitle2);
    font-weight: var(--font-weight-2);
    padding: var(--spacing-size-seven) 0;
    letter-spacing: 0.031rem;
}

.text a {
    text-decoration: underline;
    text-underline-mode: under;
}

.quote {
    font-family: "TWK Everett", sans-serif;
    font-size: var(--font-size-subtitle1);
    line-height: var(--line-height-subtitle1);
    font-weight: var(--font-weight-5);
    padding: var(--spacing-size-seven) 0;
}

.buttons {
    display: flex;
    padding-bottom: var(--spacing-size-seven);
}

.divider {
    margin-bottom: var(--spacing-size-seven);
    border-bottom: 1px solid var(--border-primary);
}

@media screen and (max-width: 767px) {
    .textBlock {
        padding: 0 var(--spacing-size-five);
    }

    .halfWidth {
        width: 100%;
    }

    .twoThirdsWidth {
        width: 100%;
    }

    .third {
        width: 100%;
    }

    .featureHeading {
        font-size: var(--font-size-subtitle2);
        line-height: var(--line-height-subtitle2);
        padding: var(--spacing-size-five) 0;
    }

    .featureTextSpacing {
        padding-top: var(--spacing-size-five);
        padding-bottom: var(--spacing-size-five);
    }

    .text {
        font-size: var(--font-size-two-serif);
        line-height: var(--line-height-two-serif);
        padding: var(--spacing-size-five) 0;
    }

    .buttons {
        padding-top: var(--spacing-size-five);
        padding-bottom: var(--spacing-size-seven);
    }

    .quote {
        font-size: var(--font-size-subtitle2);
        line-height: var(--line-height-subtitle2);
        padding: var(--spacing-size-five) 0;
    }
}
