.root {
    padding-block: var(--spacing-size-nine);
}

.stack > * + * {
    margin-block-start: var(--spacing-size-nine);
}

.recommendationsBlock {
    padding: 0;
}

@media screen and (max-width: 961px) {
    .root {
        padding-block: var(--spacing-size-six);
    }

    .stack > * + * {
        margin-block-start: var(--spacing-size-six);
    }
}
