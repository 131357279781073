.root {
    display: flex;
}

.posterBackground {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
    box-sizing: border-box;
}

.posterFeatureImage {
    position: relative;
    width: 50%;
}

.posterFeatureContent {
    display: none;
}

.cover {
    display: grid;
    grid-template-areas: 'cover';
}

.cover > * {
    grid-area: cover;
}

@media screen and (max-width: 767px) {
    .posterBackground {
        display: none;
    }

    .posterFeatureImage {
        width: 100%;
    }

    .posterFeatureContent {
        padding: var(--spacing-size-six) var(--spacing-size-five);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
    }
}

@media screen and (max-width: 991px) {
    .root {
        flex-direction: column;
    }

    .posterBackground {
        padding: var(--spacing-size-six) var(--spacing-size-five);
    }
}
