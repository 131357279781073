.root {
    display: flex;
    gap: var(--spacing-size-two);
}

.top {
    align-items: start;
}

.top > button {
    margin-top: 2px;
}

.center {
    align-items: center;
}

.CheckboxRoot {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    border: solid 1px var(--color-brand-black);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.CheckboxRoot[data-state='checked'] {
    background-color: var(--color-brand-black);
}
.CheckboxRoot[data-state='unchecked'] {
    background-color: transparent;
}

.CheckboxRoot:disabled {
    cursor: not-allowed;
}

.CheckboxIndicator {
    color: var(--color-brand-white);
}

.checkIcon {
    vertical-align: middle;
    margin-top: -2px;
}

.label {
    font-family: 'TWK Everett', sans-serif;
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    font-weight: var(--font-weight-4);
    letter-spacing: 0.25px;
    color: var(--text-secondary);
}
