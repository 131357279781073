.root {
    border-top: 5px solid var(--color-brand-pay);
    background-color: rgb(240, 240, 240);
}

.root.warning {
    border-top: 5px solid var(--color-brand-warning);
}

.sidebar {
    padding: var(--spacing-size-two) var(--spacing-size-nine) var(--spacing-size-five) ;
}

.page {
    padding: var(--spacing-size-one) var(--spacing-size-seven) var(--spacing-size-five)  var(--spacing-size-four);
}

@media screen and (max-width: 767px) {
    .sidebar {
        padding: var(--spacing-size-two) var(--spacing-size-five) var(--spacing-size-five) var(--spacing-size-five);
    }
}

.title {
    margin: var(--spacing-size-three) 0;
}
