.panels {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    min-height: 100vh;
}

.panels::-webkit-scrollbar {
    display: none;
}

.panel {
    position: relative;
    width: 504px;
    display: flex;
    flex-direction: column;
}

.storesFeature {
    padding: 0 var(--spacing-size-nine) var(--spacing-size-nine) var(--spacing-size-nine);
}

.drawerBody {
    padding-top: 0 !important;
}

@media screen and (min-width: 1150px) {
    .wideDrawer {
        --width: 1008px !important;
    }
}

@media screen and (min-width: 767px) and (max-width: 1150px) {
    .panel {
        width: 50%;
    }

    .wideDrawer {
        --width: 100% !important;
    }
}

.features {
    background: var(--color-brand-white);
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
    min-height: 100vh;
    position: relative;
}

.featuresPadding {
    padding-top: var(--spacing-size-ten)
}

.features::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 1150px) {
    .panel {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .features {
        display: none;
    }
}
