.root {
    padding: var(--spacing-size-nine) 0;
}

.containImage {
    padding: var(--spacing-size-nine);
}

.containImage .caption {
    padding-left: 0;
}

.caption {
    padding-top: var(--spacing-size-six);
    padding-left: var(--spacing-size-nine);
}

@media screen and (max-width: 991px) {
    .containImage {
        padding: var(--spacing-size-five);
    }

    .caption {
        padding-left: var(--spacing-size-five);
    }
}
