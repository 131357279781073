.reviewsRoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 50%;
}
.reviewsHeader {
    display: flex;
    padding-bottom: var(--spacing-size-six);
    justify-content: space-between;
    gap: var(--spacing-size-five);
}
.reviews {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-size-six);
}
@media screen and (max-width: 991px) {
    .reviewsRoot {
        width: 80%;
    }
}
@media screen and (max-width: 767px) {
    .reviewsHeader {
        flex-direction: column;
        justify-content: flex-start;
    }
    .reviewsRoot {
        width: 100%;
    }
}
