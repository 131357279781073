.summaryHeading {
    padding: var(--spacing-size-five) 0;
}

.summaryRow {
    padding: var(--spacing-size-six) 0;
    display: flex;
    justify-content: space-between;
}

.notice {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-size-two);
    padding: var(--spacing-size-three) var(--spacing-size-four) var(--spacing-size-four) var(--spacing-size-four);
    background-color: var(--color-brand-cement);
}

.cta {
    padding: var(--spacing-size-five) 0;
}

.button {
    width: 100%;
}

.button:hover {
    color: black;
}

.assistance {
    padding: var(--spacing-size-five) 0;
    display: flex;
    flex-wrap: wrap;
}

.divider {
    display: block;
    height: 1px;
    background-color: var(--text-primary);
}

.dividerGray {
    display: block;
    height: 1px;
    background-color: var(--color-brand-aluminium);
}

.dividerPay {
    display: block;
    height: var(--spacing-size-one);
    background-color: var(--color-brand-pay);
}

.dot {
    height: 12px;
    width: 12px;
    border-radius: 999px;
    background-color: var(--color-brand-black);
    margin-left: var(--spacing-size-two);
}

.supportLink {
    text-decoration: underline;
    display: flex;
    align-items: center;
    padding: 0 var(--spacing-size-two);
}

.cta.desktopOnly {
    display: block
}

.cta.mobileOnly {
    display: none;
}

.totalContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--spacing-size-four);
    background-color: var(--color-brand-white);
}

.totalContainer.mobileOnly {
    display: none;
}

.total {
    display: flex;
    align-items: center;
    gap: var(--spacing-size-two);
}

.desktopOnly {
    display: block;
}

.totalStrikethrough {
    text-decoration: line-through;
    margin-left: var(--spacing-size-one);
    margin-right: var(--spacing-size-two);
    color: var(--color-brand-titanium);
}

@media screen and (max-width: 991px) {
    .assistance {
        display: none;
    }

    .cta.desktopOnly {
        display: none;
    }

    .cta.mobileOnly {
        display: block;
        padding: 0;
    }

    .totalContainer.mobileOnly {
        display: flex;
    }

    .desktopOnly {
        display: none;
    }
}
