.root {
    display: flex;
    justify-content: flex-start;
    flex: 1;
}

.item {
    height: 24px;
    padding-right: var(--spacing-size-four);
}

.active {
    text-decoration: underline;
}

.compact {
    font-family: "TWK Everett", sans-serif;
    font-size: var(--font-size-copy3);
    line-height: var(--font-size-copy3);
    font-weight: var(--font-weight-4);
}
