.root {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-size-six);
}

.root time {
    color: var(--text-secondary);
}

.stack {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-size-four);
}

.ratingLabel {
    color: var(--text-secondary);
}

.reviewRating {
    display: flex;
}

.reviewRating dl {
    margin-inline-end: 4px;
}

.review {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-size-three);
}

.reviewProduct {
    padding: 0;
    margin: 0;
}

.reviewComment {
    padding: 0 0 var(--spacing-size-two) 0;
}

.ratingFilled {
    background-color: var(--color-brand-black);
}

.ratingUnfilled {
    background-color: var(--color-brand-steel);
}

.ratingPoint {
    width: 32px;
    height: 4px;
}

.ratingDisplay {
    display: inline-flex;
    gap: 1px;
    max-width: 10rem;
}

.authorDate {
    display: inline-flex;
    flex-direction: column;
    gap: var(--spacing-size-two);
}

.average {
    display: flex;
    gap: var(--spacing-size-two);
}

.reply {
    display: flex;
    align-items: start;
    gap: var(--spacing-size-five);
}

.brand {
    flex-shrink: 0;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--spacing-size-one);
}