.root {
    padding: var(--spacing-size-three) var(--spacing-size-three) var(--spacing-size-three) 0;
    display: flex;
    gap: var(--spacing-size-two);
    border-radius: var(--border-radius-xxs);
    width: 100%;
}

.image > img {
    width: 100%;
    height: auto;
    display: block;
}

.title {
    padding-bottom: var(--spacing-size-one);
}

.priceContainer {
    display: flex;
    padding-bottom: var(--spacing-size-one);
    gap: var(--spacing-size-two);
}

.compareAtPrice {
    text-decoration: line-through;
    color: var(--color-brand-titanium);
}

.colour {
    color: var(--color-brand-titanium);
}

.sizeSelector {
    display: flex;
    align-items: center;
    gap: var(--spacing-size-two);
}

.sizeLabel {
    color: var(--color-brand-titanium);
}

.variant {
    position: relative;
}

.variantSelect {
    background: none;
    border: none;
    outline: none;
    padding: var(--spacing-size-two) var(--spacing-size-one) var(--spacing-size-two) 0;
    width: 50px;
    color: var(--text-primary);
    -moz-appearance: none;
    -webkit-appearance: none;
}

.variantSelect::-ms-expand {
    display: none;
}

.variantSelect.oneSize {
    width: 72px;
}

.variantVector {
    position: absolute;
    right: 5px;
    top: 9px;
    pointer-events: none;
}

.flip {
    top: 10px;
    transform: rotate(180deg);
}

.faded {
    color: var(--surface-secondary);
}

@media screen and (max-width: 767px) {
    .title[class*='mmds-component-one-detail'] {
        font-size: var(--font-size-component2);
        line-height: var(--line-height-component2);
    }

    .priceContainer[class*='mmds-component-one-detail'] {
        font-size: var(--font-size-component2);
        line-height: var(--line-height-component2);
    }

    .colour[class*='mmds-component-one-detail'] {
        font-size: var(--font-size-component2);
        line-height: var(--line-height-component2);
    }

    .sizeLabel[class*='mmds-component-one-detail'] {
        font-size: var(--font-size-component2);
        line-height: var(--line-height-component2);
    }

    .variant[class*='mmds-component-one-detail-link'] {
        font-size: var(--font-size-component2);
        line-height: var(--line-height-component2);
    }

    .variantVector {
        right: 9px;
        top: 6px;
    }
}
