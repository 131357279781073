.root {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.summary {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-size-five) var(--spacing-size-nine);
    background-color: var(--color-brand-white);
}

@media screen and (max-width: 767px) {
    .summary {
        padding: var(--spacing-size-five);
    }
}

.totalStrikethrough {
    text-decoration: line-through;
    margin-left: var(--spacing-size-one);
    margin-right: var(--spacing-size-two);
    color: var(--color-brand-titanium);
}
