.container {
    margin-bottom: 48px;
    border-bottom: 1px solid var(--color-brand-aluminium);
}

.allCategory {
    align-items: center;
    /*background-color: white;*/
    display: flex;
    padding: var(--spacing-size-six) 0;
    inset: 0;
    justify-content: end;
    position: sticky;
    z-index: 1;
    gap: var(--spacing-size-two);
    .allCategory {
        min-width: 200px;
    }
}

@media (min-width: 1028px) {
    .allCategory {
        min-width: 360px;
    }
}

.allCategory.compact {
    padding: 0 0 var(--spacing-size-six) 0;
}

.wrapper {

    padding-top: var(--spacing-size-seven);
}