.root {
    display: flex;
    align-items: center;
    color: var(--text-primary);
    height: 100%;
    background-color: var(--background-color);
}

.title {
    margin-inline: auto;
    padding-left: var(--spacing-size-three);
}

.storiesFeatureDrawBody {
    padding-top: 0;
}

.flag {
    width: 20px;
    padding-right: var(--spacing-size-one);
}

.footer {
    display: flex;
    flex-direction: row;
    grid-column-gap: var(--spacing-size-three);
    width: 100%;
    padding-bottom: var(--spacing-size-two);
}

.footer button {
    text-align: left;
}
