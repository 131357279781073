.wrapper {
    margin-bottom: var(--spacing-size-nine);
    margin-right: var(--spacing-size-nine);
    margin-left: var(--spacing-size-nine);
}

.heading {
    margin-bottom: var(--spacing-size-nine);
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-size-six);
    max-width: 100%;
}

.double {
    grid-column: span 2;
}

.loadMore {
    display: flex;
    justify-self: center;
    margin-bottom: var(--spacing-size-nine);
}

.topicsWrapper {
    position: sticky;
    top: var(--spacing-size-nine);
    margin-bottom: var(--spacing-size-ten);
    z-index: 5;
}

.topicsWrapper > div > button {
    font-weight: var(--font-weight-3) !important;
    letter-spacing: -0.5px;
}

@media screen and (max-width: 991px) {
    .grid {
        grid-template-columns: 1fr 1fr;
        margin: 0 var(--spacing-size-five);
    }
    
    .wrapper {
        margin-right: 0;
        margin-left: 0;
    }

    .heading {
        margin-left: var(--spacing-size-five)
    }
    .topicsWrapper {
        margin-left: var(--spacing-size-five)
    }
}

@media screen and (max-width: 481px) {
    .grid {
        grid-template-columns: 1fr;
        margin: 0 var(--spacing-size-five);
    }
}

@media screen and (max-width: 767px) {
    .title[class*='mmds-title-two-alt'] {
        font-size: var(--font-size-title5);
        line-height: var(--line-height-title5);
    }
}
