/* Poster Feature Stack Variant */
.featureStackRoot {
    display: flex;
}

.featureStackBG {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing-size-nine);
    width: 50%;
}

.featureStackImage {
    position: relative;
    width: 50%;
}

.featureStackImage > div {
    height: 100%;
}

.featureStackImage > div > img {
    height: 100%;
    object-fit: cover;
}

.featureStackContent {
    display: none;
}

.productTitle {
    font-family: 'TWK Everett Mono', sans-serif;
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    font-weight: var(--font-weight-3);
    letter-spacing: -0.25px;
}

.content {
    font-family: 'TWK Everett', sans-serif;
    font-size: var(--font-size-subtitle1);
    line-height: var(--line-height-subtitle1);
    font-weight: var(--font-weight-5);
}

.thumbnail {
    display: flex;
    flex-direction: row;
    column-gap: var(--spacing-size-three);
    padding-bottom: var(--spacing-size-seven);
    cursor: pointer;
}

.button {
    padding-top: var(--spacing-size-nine);
    display: flex;
    flex-wrap: wrap;
}

.productTitleForImage {
    display: none;
}

.thumbnail img {
    cursor: pointer;
}

.thumbnailSelected {
    background-color: var(--surface-invert);
    height: 2px;
}

@media screen and (max-width: 991px) {
    /* Poster Feature Stack Variant */
    .featureStackRoot {
        flex-direction: column-reverse;
    }

    .featureStackBG {
        width: 100%;
        height: 524px;
        padding: var(--spacing-size-six) var(--spacing-size-five);
        justify-content: flex-end;
    }

    .featureStackImage {
        width: 100%;
        position: relative;
        box-sizing: border-box;
    }

    .productTitle {
        position: absolute;
        display: none;
        padding: var(--spacing-size-six) var(--spacing-size-five);
    }

    .productTitleForImage {
        position: absolute;
        top: 0;
        left: 0;
        font-family: 'TWK Everett Mono', sans-serif;
        font-size: var(--font-size-component1);
        line-height: var(--line-height-component1);
        font-weight: var(--font-weight-3);
        letter-spacing: -0.25px;
        padding: var(--spacing-size-six) var(--spacing-size-five);
        display: block;
        color: var(--color-brand-white);
        mix-blend-mode: difference;
    }

    .content {
        font-size: var(--font-size-subtitle2);
        line-height: var(--line-height-subtitle2);
    }

    .button {
        padding-top: var(--spacing-size-six);
    }
}
